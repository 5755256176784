import { StyleSheet, View, Text } from "react-native";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";

import { MAIN_COLOR } from "../constants/color";
import {
  NormalTextSize,
  PaddingHorizontal,
} from "../constants/responsive/SizeResponsive";

export default function HeaderContacts() {
  return (
    <View style={styles.body}>
      <View style={styles.leftContainer}>
        <View style={styles.contentBox}>
          <Ionicons name="call" size={14} color="#fff" />
          <Text style={styles.text}>+62 234 274 222</Text>
        </View>
        <View style={styles.contentBox}>
          <Ionicons name="mail" size={14} color="#fff" />
          <Text style={styles.text}>info@sayidsabiq.ac.id</Text>
        </View>
        <View style={{ ...styles.contentBox, marginStart: 9 }}>
          <FontAwesome5 name="facebook-f" size={14} color="#fff" />
        </View>
        <View style={styles.contentBox}>
          <FontAwesome5 name="twitter" size={14} color="#fff" />
        </View>
        <View style={styles.contentBox}>
          <FontAwesome5 name="instagram" size={14} color="#fff" />
        </View>
      </View>
      <View style={styles.rightContainer}>
        <Text style={styles.text}>Contact</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    flexDirection: "row",
    height: 30,
    width: "100%",
    backgroundColor: MAIN_COLOR,
    paddingHorizontal: PaddingHorizontal(),
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    color: "#fff",
    fontSize: NormalTextSize(),
    fontWeight: "500",
    marginStart: 1,
  },
  leftContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "flex-start",
  },
  contentBox: {
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "flex-start",
    marginEnd: 8,
  },
  rightContainer: { flex: 1, alignItems: "flex-end" },
});
