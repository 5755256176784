import { Dimensions, Text } from "react-native";
import { NormalTextSize } from "./SizeResponsive";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

export function ScreenDimension() {
  return (
    <Text>
      {windowWidth} X {windowHeight} ||
      {NormalTextSize()}
    </Text>
  );
}
