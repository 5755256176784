import { View, StyleSheet } from "react-native";
import HeaderContacts from "../headerContacts";
import InformationNavigation from "./components/InformationNavigation";

export default function InformationHeader({ setPage, page }) {
  return (
    <View>
      <HeaderContacts />
      <InformationNavigation setPage={setPage} page={page} />
    </View>
  );
}

const styles = StyleSheet.create({
  logo: { width: 95, height: 95 },
});
