import { Text, View, ImageBackground, StyleSheet } from "react-native";
import { MAIN_COLOR, RGBA_MAIN_COLOR, SHADOW_STYLE } from "../constants/color";
import { HexToRGB } from "../helpers/hextorgba";

export default function NewsCard({ data }) {
  const { images, description, title } = data;

  return (
    <View style={styles.body}>
      <ImageBackground
        source={{
          uri: images[0],
        }}
        resizeMode="cover"
        style={styles.image}
      >
        <View style={styles.backgroundContainer}>
          <Text style={styles.textTitle}>{title}</Text>
          <Text style={styles.textContent}>{description}</Text>
        </View>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    width: 300,
    height: (300 * 9) / 16,
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    backgroundColor: MAIN_COLOR,

    ...SHADOW_STYLE,
  },
  textTitle: { fontSize: 20, fontWeight: "600", color: "#fff" },
  textContent: {
    color: "#fff",
  },
  backgroundContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "flex-end",
    backgroundColor: HexToRGB("#000", 0.5),
  },
  image: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    borderRadius: 20,
  },
});
