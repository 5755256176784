export const MAIN_COLOR = "#EC9706";
export const LIGHT_MAIN_COLOR = "#fff0d6";

export const DARK_TEXT = "#5e5e5e";

export const SHADOW_STYLE = {
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 12,
  },
  shadowOpacity: 0.58,
  shadowRadius: 16.0,

  elevation: 24,
};
