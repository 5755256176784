import { Image, StyleSheet, View, Text, Pressable } from "react-native";
import { DARK_TEXT, MAIN_COLOR } from "../../../constants/color";
import { Ionicons } from "@expo/vector-icons";
import {
  ContainerVisibility,
  PaddingHorizontal,
} from "../../../constants/responsive/SizeResponsive";
import {
  NAV_AKADEMIK,
  NAV_KALENDERAKADEMIK,
  NAV_KEMAHASISWAAN,
  NAV_SOSIALISASI,
} from "../../../constants/NavigationSwitch";

export default function InformationNavigation({ setPage, page }) {
  function MenuList() {
    if (ContainerVisibility()) {
      return (
        <View style={styles.menuItems}>
          <View style={styles.navContainer}>
            <Text
              style={styles.textNav}
              onPress={() => {
                setPage(NAV_KEMAHASISWAAN);
              }}
            >
              KEMAHASISWAAN
            </Text>
          </View>
          <View style={styles.navContainer}>
            <Text
              style={styles.textNav}
              onPress={() => {
                setPage(NAV_AKADEMIK);
              }}
            >
              AKADEMIK
            </Text>
          </View>
          <View style={styles.navContainer}>
            <Text
              style={styles.textNav}
              onPress={() => {
                setPage(NAV_SOSIALISASI);
              }}
            >
              SOSIALISASI
            </Text>
          </View>
          <View style={styles.navContainer}>
            <Text
              style={styles.textNav}
              onPress={() => {
                setPage(NAV_KALENDERAKADEMIK);
              }}
            >
              KALENDER AKADEMIK
            </Text>
          </View>
          <View style={styles.navContainer}>
            <Ionicons name="search" size={24} color={DARK_TEXT} />
          </View>
        </View>
      );
    }
  }
  return (
    <View style={styles.body}>
      <View style={styles.mainLogoContainer}>
        <Pressable>
          <Image
            source={require("../../../../assets/logo.png")}
            style={styles.logo}
          />
        </Pressable>
        <View style={styles.title}>
          <Text style={styles.textSTAI}>SEKOLAH TINGGI AGAMA ISLAM</Text>
          <Text style={styles.textName}>SAYID SABIQ INDRAMAYU</Text>
          {page && (
            <Text
              style={{ ...styles.textName, color: DARK_TEXT }}
              onPress={() => setPage()}
            >
              {page && `${page.toUpperCase()}`}
            </Text>
          )}
        </View>
      </View>
      {MenuList()}
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    width: "100%",

    paddingVertical: 10,
    flexDirection: "column",
    paddingHorizontal: PaddingHorizontal(),
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: DARK_TEXT,
    borderBottomWidth: 1,
  },
  mainLogoContainer: { flexDirection: "row", marginBottom: 10 },
  logo: { width: 95, height: 95 },
  title: { justifyContent: "center", marginStart: 5 },
  textSTAI: { fontWeight: "500", color: MAIN_COLOR },
  textName: { fontSize: 20, fontWeight: "700", color: MAIN_COLOR },
  menuItems: { flexDirection: "row", alignItems: "center" },
  navContainer: {
    marginStart: 30,
  },
  textNav: {
    color: DARK_TEXT,
    fontSize: 16,
    fontWeight: "700",
  },
});
