import {
  Text,
  View,
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import {
  CarouselCardContentHeight,
  NormalTextSize,
  PaddingHorizontal,
  TitleTextSize,
  windowHeight,
  windowWidth,
} from "../constants/responsive/SizeResponsive";
import { HexToRGB } from "../helpers/hextorgba";

export default function CarouselCard({ data, navigation }) {
  const { images, description, title } = data;

  return (
    <View style={styles.body}>
      <ImageBackground
        style={styles.imageBackground}
        // imageStyle={{ borderRadius: 20 }}
        source={{
          uri: images[0],
        }}
        resizeMode="cover"
      >
        <TouchableOpacity
          style={styles.container}
          onPress={() => navigation("ArticleDetail")}
        >
          <View style={styles.content}>
            <Text style={styles.textTitle}>{title}</Text>
            <Text style={styles.textCaption}>{description}</Text>
          </View>
        </TouchableOpacity>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    width: windowWidth,
    height: windowHeight * 0.6 - 20,
    backgroundColor: "blue",
  },
  imageBackground: {
    width: "100%",
    height: "100%",
  },
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-end",
  },
  content: {
    width: "100%",
    height: CarouselCardContentHeight(),
    paddingHorizontal: PaddingHorizontal(),
    paddingVertical: 30,
    justifyContent: "space-between",
    backgroundColor: HexToRGB("#000", 0.6),
  },
  textTitle: {
    fontSize: TitleTextSize(),
    fontWeight: "700",
    color: "#fff",
  },
  textCaption: {
    fontSize: NormalTextSize(),
    color: "#fff",
  },
});
