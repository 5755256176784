import { Text, View, StyleSheet } from "react-native";
import InformationCard from "../../../../components/InformationCard";
import { DARK_TEXT } from "../../../../constants/color";
import { PaddingHorizontal } from "../../../../constants/responsive/SizeResponsive";

export default function CategoryLanding() {
  return (
    <View style={styles.body}>
      <View
        style={{
          flex: 2,
          marginTop: 15,
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
      >
        <InformationCard />
        <InformationCard />
      </View>
      <View
        style={{ flex: 1, borderStartWidth: 1, borderStartColor: DARK_TEXT }}
      ></View>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    paddingHorizontal: PaddingHorizontal(),
  },
});
