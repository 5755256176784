import { Dimensions } from "react-native";

export const windowWidth = Dimensions.get("window").width;
export const windowHeight = Dimensions.get("window").height;

/* ============== TEXT ================ */
export function TitleTextSize() {
  switch (true) {
    case windowWidth < 640:
      return 16;
    case windowWidth < 1007:
      return 18;
    default:
      return 20;
  }
}
export function H1TextSize() {
  switch (true) {
    case windowWidth < 640:
      return 14;
    case windowWidth < 1007:
      return 16;
    default:
      return 18;
  }
}

export function NormalTextSize() {
  switch (true) {
    case windowWidth < 640:
      return 10;
    case windowWidth < 1007:
      return 12;
    default:
      return 14;
  }
}
/* ============== END TEXT ================ */

/* ============== BOOLEAN ================ */

export function ContainerVisibility() {
  switch (true) {
    case windowWidth < 640:
      return false;

    default:
      return true;
  }
}

/* ============== END BOOLEAN ================ */

export function PaddingHorizontal() {
  switch (true) {
    case windowWidth < 1007:
      return 5;
    default:
      return "20%";
  }
}

export function CarouselCardContentHeight() {
  switch (true) {
    case windowWidth < 1007:
      return "20%";
    default:
      return "35%";
  }
}
