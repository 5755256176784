import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import OutletInformation from "./components/OutletInformation";
import InformationHeader from "../../components/InformationHeader/InformationHeader";
import MainFooter from "../../components/MainFooter";
import { DARK_TEXT } from "../../constants/color";
import { PaddingHorizontal } from "../../constants/responsive/SizeResponsive";

export default function InformationPage() {
  const [page, setPage] = useState();

  return (
    <>
      <InformationHeader setPage={setPage} page={page} />
      <View style={styles.body}>
        <OutletInformation page={page} />
      </View>
      <MainFooter />
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
  },
});
