import { Text, View, StyleSheet } from "react-native";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";

import { DARK_TEXT, LIGHT_MAIN_COLOR, MAIN_COLOR } from "../constants/color";
import {
  H1TextSize,
  NormalTextSize,
  PaddingHorizontal,
  TitleTextSize,
} from "../constants/responsive/SizeResponsive";

function HeaderBox() {
  return (
    <View
      style={{
        width: "100%",
        height: 5,
        backgroundColor: MAIN_COLOR,
      }}
    />
  );
}
export default function MainFooter() {
  return (
    <View style={styles.body}>
      <HeaderBox />
      <View
        style={{
          paddingHorizontal: PaddingHorizontal(),
          flexDirection: "row",
          paddingTop: 5,
          paddingBottom: 10,
        }}
      >
        <View style={styles.contentsContainer}>
          <Text style={{ ...styles.textTitle, fontSize: H1TextSize() * 0.7 }}>
            SEKOLAH TINGGI AGAMA ISLAM
          </Text>
          <Text style={styles.textTitle}>SAYID SABIQ INDRAMAYU</Text>
          <Text style={{ ...styles.textDetail, marginTop: 5 }}>
            Jalan Raya Panyindangan Wetan No.1, Sindang
          </Text>
          <Text style={styles.textDetail}>
            Kab. Indramayu, Jawa Barat, Indonesia
          </Text>
          <Text style={{ ...styles.textDetail, marginTop: 10 }}>
            <b>Phone :</b> +62 234 274 222
          </Text>
          <Text style={styles.textDetail}>
            <b>Email :</b> info@sayidsabiq.ac.id
          </Text>
        </View>
        <View style={styles.media}>
          <View style={{ ...styles.contentBox, marginStart: 9 }}>
            <FontAwesome5 name="facebook-f" size={30} color="#fff" />
          </View>
          <View style={styles.contentBox}>
            <FontAwesome5 name="twitter" size={30} color="#fff" />
          </View>
          <View style={styles.contentBox}>
            <FontAwesome5 name="instagram" size={30} color="#fff" />
          </View>
        </View>
      </View>
    </View>
  );
}
// 81310 Johor Bahru
// Johor, Malaysia.

// Phone : +6 07-553 3333
// Email : corporate@utm.my
// Office Hours : Sun – Wed (8:00 am – 5:00 pm)
// Thursday (8:00 am – 3:30 pm)

// Website : utm.my
const styles = StyleSheet.create({
  body: { width: "100%", backgroundColor: DARK_TEXT },
  contentsContainer: {
    flex: 1,
  },
  textTitle: { color: "#fff", fontSize: H1TextSize(), fontWeight: "700" },
  textDetail: { color: "#fff", fontSize: NormalTextSize(), fontWeight: "500" },
  media: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  contentBox: {
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "flex-start",
    marginStart: 20,
  },
});
