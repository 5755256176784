import { Text, View, StyleSheet, ImageBackground } from "react-native";
import InformationCard from "../../../components/InformationCard";
import { DARK_TEXT, MAIN_COLOR } from "../../../constants/color";
import {
  PaddingHorizontal,
  TitleTextSize,
} from "../../../constants/responsive/SizeResponsive";
import { HexToRGB } from "../../../helpers/hextorgba";

export default function InformationLanding({ page }) {
  return (
    <View style={styles.body}>
      <View style={styles.pageTitle}>
        <ImageBackground
          source={{
            uri: "https://foto.data.kemdikbud.go.id/getImage/69754533/4.jpg",
          }}
          style={styles.imageBackground}
          resizeMode="cover"
          resizeMethod="auto"
        >
          <View style={styles.textContainer}>
            <Text style={{ ...styles.textTitle }} onPress={() => setPage()}>
              INFORMASI {page && `- ${page.toUpperCase()}`}
            </Text>
          </View>
        </ImageBackground>
      </View>
      <View style={styles.cardsContainer}>
        <InformationCard />
        <InformationCard />
        <InformationCard />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    width: "100%",
    // flexDirection: "row",
    alignItems: "center",
  },
  pageTitle: {
    width: "100%",
    height: 100,
    backgroundColor: "#fff",
  },
  imageBackground: { height: "100%", width: "100%" },
  textContainer: {
    flex: 1,
    width: "100%",
    backgroundColor: HexToRGB("#ffffff", 0.8),
    justifyContent: "center",
    alignItems: "center",
  },
  textTitle: {
    color: DARK_TEXT,
    fontSize: TitleTextSize(),
    fontWeight: "700",
  },
  cardsContainer: {
    marginTop: 15,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingHorizontal: PaddingHorizontal(),
  },
});
