import { Text, View } from "react-native";
import {
  NAV_AKADEMIK,
  NAV_KALENDERAKADEMIK,
  NAV_KEMAHASISWAAN,
  NAV_SOSIALISASI,
} from "../../../constants/NavigationSwitch";
import CategoryLanding from "./InformationCategory/CategoryLanding";
import InformationLanding from "./InformationLanding";

export default function OutletInformation({ page }) {
  console.log(page ? true : false);
  if (page) {
    return <CategoryLanding />;
  } else {
    return <InformationLanding page={page} />;
  }
}
