import { useEffect, useState } from "react";
import { View, StyleSheet, Button } from "react-native";
import CarouselCard from "../../../components/CarouselCard";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HexToRGB } from "../../../helpers/hextorgba";

export default function LandingCarousel({ dummyData, navigation }) {
  const [activeIndex, setActiveIndex] = useState(0);
  // cons[(data, setData)] = useState([]);
  const data = dummyData;
  const dataToShow = data[activeIndex];
  console.log(navigation);

  useEffect(() => {
    setInterval(() => {
      setActiveIndex((value) => {
        if (value == data.length - 1) return 0;
        else return ++value;
      });
    }, 5000);
  }, []);

  return (
    <>
      {/* <Text>{activeIndex}</Text> */}
      <View style={styles.body}>
        <CarouselCard data={dataToShow} navigation={navigation.navigate} />
        <View style={styles.indicator}>
          {data.map((el, index) => {
            if (index == activeIndex) {
              return (
                <MaterialCommunityIcons
                  name="dots-hexagon"
                  size={24}
                  color="black"
                  style={styles.iconIndicatorInactive}
                  onPress={() => {
                    setActiveIndex(index);
                  }}
                  key={index}
                />
              );
            } else {
              return (
                <MaterialCommunityIcons
                  name="dots-circle"
                  size={24}
                  color="black"
                  style={styles.iconIndicatorActive}
                  onPress={() => {
                    setActiveIndex(index);
                  }}
                  key={index}
                />
              );
            }
          })}
        </View>
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  body: {
    width: "100%",
    height: "65%",
    marginBottom: 20,
  },
  imageBackgroud: {
    width: "100%",
    height: "100%",
  },
  indicator: {
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  iconIndicatorActive: {
    color: HexToRGB("#000000", 0.5),
    marginHorizontal: 3,
  },
  iconIndicatorInactive: {},
});
