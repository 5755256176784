import { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Text,
  View,
  FlatList,
  StyleSheet,
} from "react-native";
import NewsCard from "../../../components/NewsCard";
import { PaddingHorizontal } from "../../../constants/responsive/SizeResponsive";

export default function LandingNews({ dummyData }) {
  const renderItem = ({ item }) => <NewsCard data={item} />;

  const [dataToRender, setDataToRender] = useState([]);
  useEffect(() => {
    dummyData && setDataToRender((state) => dummyData.slice(0, 5));
  }, [dummyData]);

  function addDataToRender() {
    if (dummyData.length > dataToRender.length) {
      setDataToRender((state) => {
        const addData = dummyData.slice(state.length, state.length + 3);
        console.log(addData);
        addData.forEach((el) => {
          state.push(el);
        });

        return state;
      });
    }
  }

  return dataToRender ? (
    <View style={styles.body}>
      <View style={styles.titleContainer}>
        <Text style={styles.textTitle}>Berita Kampus</Text>
      </View>
      <FlatList
        data={dataToRender}
        renderItem={renderItem}
        key="_"
        keyExtractor={(item) => `_${item.id}`}
        horizontal
        ListEmptyComponent={() => {
          return <Text>We'll post some informations soon ...</Text>;
        }}
        ListFooterComponent={() => {
          return (
            dataToRender.length < 10 && (
              <Text
                onPress={() => {
                  addDataToRender();
                }}
              >
                Load More
              </Text>
            )
          );
        }}
        ListFooterComponentStyle={{
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </View>
  ) : (
    <ActivityIndicator size={"large"} />
  );
}
const styles = StyleSheet.create({
  body: {
    width: "100%",
    paddingVertical: 30,
    paddingHorizontal: PaddingHorizontal(),
  },
  titleContainer: {},
  textTitle: { fontWeight: "700", fontSize: 20 },
});
