import { Image, StyleSheet, View, Text } from "react-native";
import {
  DARK_TEXT,
  LIGHT_MAIN_COLOR,
  MAIN_COLOR,
} from "../../../constants/color";
import { H1TextSize } from "../../../constants/responsive/SizeResponsive";

export default function CourseOptions(props) {
  return (
    <View style={styles.body}>
      <View style={styles.sideContainer}>
        <HeaderBox />
        <View style={{ padding: 10 }}>
          <Text style={styles.textTitle}>
            PENDIDIKAN AGAMA ISLAM{" "}
            <Text style={{ ...styles.textTitle, color: DARK_TEXT }}>
              {" (S1)"}
            </Text>
          </Text>
        </View>
      </View>
      <View style={styles.logoContainer}>
        <HeaderBox />
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Image
            source={require("../../../../assets/logo.png")}
            style={styles.logo}
          />
        </View>
      </View>
      <View style={styles.sideContainer}>
        <HeaderBox />
        <View style={{ padding: 10 }}>
          <Text style={styles.textTitle}>
            PERBANKAN SYARI'AH
            <Text style={{ ...styles.textTitle, color: DARK_TEXT }}>
              {" (S1)"}
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );
}
const defSize = 300;

const styles = StyleSheet.create({
  body: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    height: defSize,
    width: defSize,
    backgroundColor: "#573702",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,

    elevation: 7,
  },
  logo: { width: defSize * 0.9, height: defSize * 0.9 },
  sideContainer: {
    width: defSize * 1.2,
    height: defSize * 0.8,
    backgroundColor: LIGHT_MAIN_COLOR,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,

    elevation: 7,
  },
  textTitle: {
    fontWeight: "700",
    fontSize: H1TextSize(),
    alignSelf: "center",
  },
});

function HeaderBox() {
  return (
    <View
      style={{
        width: "100%",
        height: 10,
        backgroundColor: MAIN_COLOR,
      }}
    />
  );
}
