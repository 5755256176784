import { Image, StyleSheet, View, Text } from "react-native";
import { DARK_TEXT, MAIN_COLOR } from "../../../constants/color";
import { Ionicons } from "@expo/vector-icons";
import {
  ContainerVisibility,
  PaddingHorizontal,
} from "../../../constants/responsive/SizeResponsive";

export default function LandingNavigation({ navigate }) {
  console.log(navigate);
  function MenuList({ navigate }) {
    if (ContainerVisibility()) {
      return (
        <View style={styles.rightContainer}>
          <View style={styles.navContainer}>
            <Text style={styles.textNav}>PROFIL</Text>
          </View>
          <View style={styles.navContainer}>
            <Text
              style={styles.textNav}
              onPress={() => navigate("InformationPage")}
            >
              INFORMASI
            </Text>
          </View>

          <View style={styles.navContainer}>
            <Ionicons name="search" size={24} color={DARK_TEXT} />
          </View>
        </View>
      );
    }
  }

  return (
    <View style={styles.body}>
      <View style={styles.leftContainer}>
        <Image
          source={require("../../../../assets/logo.png")}
          style={styles.logo}
        />
        <View style={styles.title}>
          <Text style={styles.textSTAI}>SEKOLAH TINGGI AGAMA ISLAM</Text>
          <Text style={styles.textName}>SAYID SABIQ INDRAMAYU</Text>
        </View>
      </View>
      <MenuList navigate={navigate} />
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    width: "100%",
    height: 120,
    paddingVertical: 10,
    flexDirection: "row",
    paddingHorizontal: PaddingHorizontal(),
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftContainer: { flexDirection: "row" },
  logo: { width: 95, height: 95 },
  title: { justifyContent: "center", marginStart: 5 },
  textSTAI: { fontWeight: "500", color: MAIN_COLOR },
  textName: { fontSize: 20, fontWeight: "700", color: MAIN_COLOR },
  rightContainer: { flexDirection: "row", alignItems: "center" },
  navContainer: {
    marginStart: 30,
  },
  textNav: {
    color: DARK_TEXT,
    fontSize: 16,
    fontWeight: "700",
  },
});
