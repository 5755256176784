import { Image, StyleSheet, Text, View } from "react-native";
import { DARK_TEXT, MAIN_COLOR, SHADOW_STYLE } from "../constants/color";
import {
  H1TextSize,
  NormalTextSize,
} from "../constants/responsive/SizeResponsive";

export default function InformationCard() {
  return (
    <View style={styles.body}>
      <Image
        source={{
          uri: "https://awsimages.detik.net.id/community/media/visual/2022/03/25/manga-one-piece_43.webp",
        }}
        style={styles.image}
        resizeMode="cover"
      />
      <View style={styles.description}>
        <Text style={styles.descriptionTitle}>
          MUGIWARA AKAN MENGALAHKAN KAIDO MINGGU INI
        </Text>
        <Text style={styles.descriptionDate}>Nov 11, 2022</Text>
        <Text style={styles.descriptionContent} numberOfLines={4}>
          Bab 922 sempat membuat fans takjub saat rilis. Bab itu ditutup dengan
          Luffy menghajar Kaido dengan Elephant Gun.
          <br />
          Mungkin saat bab itu rilis, ada yang mengira Luffy akan bisa bertarung
          seru lawan Kaido, meski itu baru awal-awal alur Wano. Bab 923
          mematahkan anggapan itu. Di bab 923, Luffy lanjut menghajar Kaido.
          Hanya saja, Kaido hanya butuh satu Raimei Hakke untuk membuat Luffy
          K.O. Yang bikin ini lebih menakjubkan adalah Luffy masih dalam wujud
          Boundman ketika kena, tapi dia tetap tumbang.
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    width: 350,
    backgroundColor: "#fff",
    borderRadius: 10,

    ...SHADOW_STYLE,
  },
  image: {
    width: "100%",
    height: 200,
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
    overflow: "hidden",
  },
  description: { width: "100%", padding: 10 },
  descriptionTitle: {
    fontSize: H1TextSize() - 1,
    fontWeight: "700",
    color: DARK_TEXT,
  },
  descriptionDate: {
    fontSize: NormalTextSize(),
    marginTop: 15,
    fontWeight: "700",
    color: MAIN_COLOR,
  },
  descriptionContent: {
    fontSize: NormalTextSize(),
    color: DARK_TEXT,
  },
});
