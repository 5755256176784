import { StyleSheet, Text, View } from "react-native";

import axios from "axios";
import { useEffect, useState } from "react";

import HeaderContacts from "../../components/headerContacts";
import { ScreenDimension } from "../../constants/responsive/responsive";
import CourseOptions from "./components/CourseOptions";
import LandingCarousel from "./components/LandingCarousel";
import LandingNavigation from "./components/LandingNavigation";
import LandingNews from "./components/LandingNews";
import MainFooter from "../../components/MainFooter";

export default function LandingPage({ navigation }) {
  const [dummyData, setDummyData] = useState();
  async function getDummyData() {
    try {
      const { data } = await axios.get("https://dummyjson.com/products");
      setDummyData(() => data.products);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDummyData();
  }, []);

  return (
    <View style={styles.container}>
      {/* <ScreenDimension /> */}
      <HeaderContacts />
      <LandingNavigation navigate={navigation.navigate} />
      {dummyData && (
        <LandingCarousel dummyData={dummyData} navigation={navigation} />
      )}
      <CourseOptions />
      {dummyData && <LandingNews dummyData={dummyData} />}
      <MainFooter />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    // justifyContent: "center",
  },
});
