import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import LandingPage from "./src/pages/landingPage/LandingPage";
import ArticleEditPage from "./src/pages/articleEditPage/ArticleEditPage";
import InformationPage from "./src/pages/information/InformationPage";
import axios from "axios";
import { BASE_AUTH } from "./src/constants/BaseUrl";

const Stack = createNativeStackNavigator();

export default function App() {
  React.useEffect(() => {
    const access_token = 
    console.log("enter");
  }, []);

  return (
    <NavigationContainer>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Home" component={LandingPage} />
        <Stack.Screen name="InformationPage" component={InformationPage} />
        <Stack.Screen name="ArticleEditPage" component={ArticleEditPage} />
        <Stack.Screen name="ArticleEdit" component={ArticleEditPage} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

async function getAccessToken(){
try {
  const access_token = await axios.get(`${BASE_AUTH}/company`,{headers:{}})
} catch (error) {
  
}
}