import React, { useEffect, useState } from "react";
import { View } from "react-native";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function ArticleEditPage() {
  const [value, setValue] = useState("");
  const quilModulesOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["link", "image", "video"],

    ["clean"], // remove formatting button
  ];

  useEffect(() => {
    console.log(value);
  }, [value]);

  return (
    <View style={{ height: 500, paddingBottom: 20 }}>
      <ReactQuill
        theme="snow"
        onChange={setValue}
        modules={{ toolbar: quilModulesOptions }}
        style={{ flex: 1, overflow: "auto", marginBottom: 20 }}
        defaultValue={value}
      />
    </View>
  );
}
